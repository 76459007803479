import React from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslations } from "next-intl";

interface EntityLock {
  lockedByDomain: string;
  lockedByEntity: string;
  lockDate: Date;
}

export type Error00001Data = EntityLock[];

export const Error00001: React.FC<{ locks: Error00001Data }> = ({ locks }) => {
  const t = useTranslations("feedback");

  return (
    locks.length > 0 && (
      <TableContainer component={Stack} sx={{ p: 5, borderRadius: "10px" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
              >
                {t("lockedByDomain")}
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
              >
                {t("lockedByEntity")}
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
              >
                {t("lockDate")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locks.map((lock, lockIndex) => (
              <TableRow key={lockIndex}>
                <TableCell>{t(lock.lockedByDomain)}</TableCell>
                <TableCell>{t(lock.lockedByEntity)}</TableCell>
                <TableCell>
                  {new Date(lock.lockDate).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

export default Error00001;
