import gql from "graphql-tag";
export const GET_ADDRESS_BY_ID = gql`
  query AddressByID($id: ID!) {
    AddressByID(id: $id) {
      id
      name
      street
      number
      addressLine2
      postalcode
      city
      country
      note
      active
    }
  }
`;
