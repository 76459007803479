"use client";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { ApolloError } from "@apollo/client";
import { useTranslations } from "next-intl";
import { Error00001, Error00001Data } from "./Error00001";
import { useGlobalModal } from "@patron/utils/context";
import { GraphQLError } from "graphql";
import { Tooltip, IconButton, Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import { ErrorGeneric } from "./ErrorGeneric";
import React from "react";

enum ErrorType {
  ErrorTypeUnknown = "00000",
  ErrorTypeEntityLocked = "00001",
}

export interface ErrorAlertProps {
  errorObject: ApolloError | Error;
}

export interface Extension {
  code: ErrorType;
  data: Error00001Data;
}

export const ErrorAlert = (props: ErrorAlertProps) => {
  const { errorObject } = props;
  const t = useTranslations("feedback");
  const { handleModal, isModalActive } = useGlobalModal();

  const ErrorDetails = (errorCode: string, error: GraphQLError) => {
    switch (errorCode) {
      case ErrorType.ErrorTypeEntityLocked:
        return <Error00001 locks={error?.extensions?.data as Error00001Data} />;
      default:
        return (
          <ErrorGeneric
            title={t(errorCode)}
            message={t(error?.message)}
            severity="error"
          />
        );
    }
  };

  const handleViewDetailsClick = (
    errorCode: string | null,
    error: GraphQLError | null
  ) => {
    if (errorCode && error) {
      // If this error is already within a modal, create a child modal
      handleModal(ErrorDetails(errorCode, error), isModalActive);
    }
  };

  if (errorObject instanceof ApolloError) {
    const errors = errorObject.graphQLErrors;
    const singleError = errors.length === 1;

    if (errors.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        {errors.map((error, index) => (
          <Alert
            variant={singleError ? "outlined" : "standard"}
            key={index}
            severity="error"
            action={
              !singleError &&
              Object.values(ErrorType).some(
                (errorType) => errorType === error?.message
              ) && (
                <Tooltip title={t("details")}>
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() =>
                      handleViewDetailsClick(
                        (error?.extensions?.code as string) || null,
                        error
                      )
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            <AlertTitle variant="body1">
              {t(error.message) as React.ReactNode}
            </AlertTitle>
            {error?.extensions?.code !== error.message && (
              <Typography variant="caption">
                {t(error?.extensions?.code as string)}
              </Typography>
            )}
            {singleError && (
              <Stack p={3}>
                {ErrorDetails(errors[0]?.extensions?.code as string, errors[0])}
              </Stack>
            )}
          </Alert>
        ))}
      </React.Fragment>
    );
  }

  return ErrorDetails(ErrorType.ErrorTypeUnknown, errorObject as GraphQLError);
};

export default ErrorAlert;
