"use client";
import { Faro } from "@grafana/faro-web-sdk";
import {
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
} from "@grafana/faro-react";
import getConfig from "next/config";

// eslint-disable-next-line func-style
export function FaroWeb(): Faro | undefined {
  const { publicRuntimeConfig } = getConfig();
  const { ANALYTICS_URL, ANALTYICS_API_KEY, ANALYTICS_ENABLED } =
    publicRuntimeConfig;
  if (!ANALYTICS_ENABLED) {
    return;
  }
  const faro = initializeFaro({
    url: ANALYTICS_URL,
    apiKey: ANALTYICS_API_KEY,
    trackWebVitalsAttribution: true,
    sessionTracking: {
      enabled: true,
      persistent: true,
    },
    app: {
      name: "patron",
    },
    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: true,
      }),
      new ReactIntegration(),
    ],
  });

  return faro;
}

export type { Faro } from "@grafana/faro-web-sdk";
