import gql from "graphql-tag";
export const GET_RELATIONCONTACTPERSON = gql`
  query GetRelatioContactPerson($QueryParams: OrganizationPersonParams!) {
    OrganizationPersons(input: $QueryParams) {
      id
      lastName
      firstName
      infix
      initials
      title
      gender
      phoneNumber
      mobileNumber
      function
      emailAddress
      active
      relation_type
      role
      mainContact
    }
  }
`;
