"use client";

import React from "react";
import { ErrorAlert } from "@patron/ui/error-alert";
import { Button } from "@mui/material";
import { useTranslations } from "next-intl";
import { FallbackProps } from "react-error-boundary";
import { Stack } from "@mui/system";

export const FallbackErrorComponent = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => (
  // const t = useTranslations("feedback");

  <Stack direction="column" minWidth="300px" spacing={5}>
    <ErrorAlert errorObject={error} />
    <Stack direction="row" justifyContent="flex-end" p={5}>
      <Button
        size="small"
        variant="contained"
        onClick={() => resetErrorBoundary()}
      >
        {/* {t("tryAgain")} */}
      </Button>
    </Stack>
  </Stack>
);

export default FallbackErrorComponent;
