"use client";

import React, { createContext } from "react";

interface TaskData {
  domain: string;
  entity: string;
  entityValue: string;
  extraProperties?: {};
}
export enum TaskActionType {
  NEWTASK = 1,
  UPDATETASK = 2,
  VIEWTASK = 3,
}

interface TaskContextType {
  domainValue: TaskData | null;
  multiTaskContext?: TaskData[];
  taskAction: TaskActionType | null;
  handleUpdateTaskDomain: (value: TaskData) => void;
  onOpenTaskSideSheet: () => void;
  onMultiTaskContextChange: (value: TaskData[]) => void;
  onTaskAction: (action: TaskActionType) => void;
  openTaskSideSheet: boolean;
  onTaskID: (value: string) => void;
  taskID: string;
}

interface Props {
  children: React.ReactNode;

  domainInputData: TaskData;
}

export const TaskContext = createContext<TaskContextType | null>(null);

export const TaskContextProvider: React.FC<Props> = ({
  children,
  domainInputData,
}) => {
  const [domainObject, setDomainObject] = React.useState<TaskData | null>(
    domainInputData || null
  );
  const [multiTaskContext, setMultiTaskContext] = React.useState<TaskData[]>(
    []
  );
  const [taskID, setTaskID] = React.useState<string>("");
  const [taskAction, setTaskAction] = React.useState<TaskActionType | null>(
    null
  );
  const [openTaskSideSheet, setOpenTaskSideSheet] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (domainInputData) {
      setDomainObject(domainInputData);
    }
  }, [domainInputData]);

  const handleUpdateTaskDomain = (inputValue: TaskData) => {
    setDomainObject(inputValue);
  };

  const onOpenTaskSideSheet = () => {
    setOpenTaskSideSheet(!openTaskSideSheet);
  };

  const onMultiTaskContextChange = (value: TaskData[]) => {
    setMultiTaskContext(value);
  };

  const onTaskAction = (action: TaskActionType) => {
    setTaskAction(action);
  };
  const onTaskID = (value: string) => {
    setTaskID(value);
  };

  return (
    <TaskContext.Provider
      value={{
        onOpenTaskSideSheet,
        handleUpdateTaskDomain,
        taskAction,
        multiTaskContext,
        onTaskAction,
        onMultiTaskContextChange,
        openTaskSideSheet,
        taskID,
        onTaskID,
        domainValue: domainObject,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
