"use client";

import React, { createContext } from "react";

interface DomainData {
  domain: string;
  entity: string;
  entityValue: string;
  extraProperties?: {};
}

interface DomainContextType {
  domainValue: DomainData | null;
  handleUpdateDomain: (value: DomainData) => void;
}

interface Props {
  children: React.ReactNode;
  domainInputData: DomainData;
}

export const DomainContext = createContext<DomainContextType | null>(null);

export const DomainProvider: React.FC<Props> = ({
  children,
  domainInputData,
}) => {
  const [domainObject, setDomainObject] = React.useState<DomainData | null>(
    domainInputData || null
  );

  React.useEffect(() => {
    if (domainInputData) {
      setDomainObject(domainInputData);
    }
  }, [domainInputData]);

  const handleUpdateDomain = (inputValue: DomainData) => {
    setDomainObject(inputValue);
  };

  return (
    <DomainContext.Provider
      value={{
        handleUpdateDomain,
        domainValue: domainObject,
      }}
    >
      {children}
    </DomainContext.Provider>
  );
};
