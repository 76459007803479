"use client";

/* eslint-disable func-style */
import { useMemo, useCallback, useContext } from "react";
import { useRouter } from "next/navigation";
import SaveNavigationContext, {
  useSetUnsavedChanges,
  useUnsavedChanges,
} from "../lib/SaveNavigationProvider";

export const useCustomRouter = () => {
  const router = useRouter();
  const context = useContext(SaveNavigationContext);
  //@ts-ignore
  const { currentPageHasUnsavedChanges, showUnsavedChangesModal } =
    useUnsavedChanges();
  const { setUnsavedChanges, clearUnsavedChanges } = useSetUnsavedChanges();

  const push = useCallback(
    (route: string, isClient: boolean) => {
      if (currentPageHasUnsavedChanges) {
        setUnsavedChanges({
          cancelButtonLabel:
            context?.saveNavigationModal?.cancelButtonLabel || "",
          message: context?.saveNavigationModal?.message || "",
          navigationButtonLabel:
            context?.saveNavigationModal?.navigationButtonLabel || "",
          navigationRoute: route,
        });
        showUnsavedChangesModal(
          context?.saveNavigationModal?.navigationRoute || ""
        );
      } else {
        clearUnsavedChanges();
        if (isClient) {
          router.push(route);
        } else {
          document.location.href = route;
        }
      }
    },
    [
      clearUnsavedChanges,
      context,
      currentPageHasUnsavedChanges,
      router,
      setUnsavedChanges,
      showUnsavedChangesModal,
    ]
  );

  const back = useCallback(() => {
    if (currentPageHasUnsavedChanges) {
      setUnsavedChanges({
        cancelButtonLabel:
          context?.saveNavigationModal?.cancelButtonLabel || "",
        message: context?.saveNavigationModal?.message || "",
        navigationButtonLabel:
          context?.saveNavigationModal?.navigationButtonLabel || "",
        navigationRoute: "back",
      });
      showUnsavedChangesModal(
        context?.saveNavigationModal?.navigationRoute || ""
      );
    } else {
      clearUnsavedChanges();

      router.back();
    }
  }, [
    clearUnsavedChanges,
    context,
    currentPageHasUnsavedChanges,
    router,
    setUnsavedChanges,
    showUnsavedChangesModal,
  ]);

  return useMemo(() => ({ push, back }), [push, back]);
};
