import gql from "graphql-tag";
export const GET_CURRENT_USER = gql`
  query CurrentLoggedinUser {
    currentUser {
      id
      userName
      userID
      roles {
        id
        roleName
        description
      }
      active
      preferences
      avatar
      permissions
      employee {
        id
        lastName
        firstName
        infix
        initials
        title
        gender
        phoneNumber
        mobileNumber
        function
        department
        dateOfBirth
        emailAddressPrivate
        emailAddressBusiness
        street
        houseNumber
        addressLine2
        postalcode
        city
        country
        note
        tags
        active
      }
    }
  }
`;
