import React, { useEffect, useRef } from "react";
import { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "@patron/data-access";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material/";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import {
  AppContextProvider,
  DomainProvider,
  SaveNavigationProvider,
} from "@patron/utils/context";
import { getTheme } from "@patron/utils/theme";
import Head from "next/head";
import { ErrorBoundary } from "react-error-boundary";
import { FallbackErrorComponent } from "@patron/ui/error-component";
import {
  NextIntlProvider,
  FaroWeb,
  Faro,
} from "@patron/utils/pagerouterconfig";
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter";
import { SnackbarProvider } from "notistack";
import { LoaderBackdrop } from "@patron/composite/loader";
import { GlobalModalProvider } from "@patron/utils/context";

LicenseInfo.setLicenseKey(
  "39b20c1effd4c09bb8a75446913ddebaTz04MDMzOSxFPTE3MzM4NDI0OTEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const Application = (props: AppProps) => {
  const faroRef = useRef<null | Faro>(null);

  useEffect(() => {
    if (!faroRef.current && typeof window !== "undefined") {
      //@ts-ignore
      faroRef.current = FaroWeb();
    }
  }, []);
  useEffect(() => {
    // removes the server-side injected styles
    const styles = document.querySelector("#jss-server-side");
    styles?.parentElement?.removeChild(styles);
  }, []);
  const { Component, pageProps } = props;
  const apolloClient = useApollo(pageProps.initialApolloState);

  return (
    <AppCacheProvider {...props}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={getTheme()}>
        <SnackbarProvider
          autoHideDuration={3000}
          maxSnack={3}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <GlobalModalProvider>
            <ErrorBoundary FallbackComponent={FallbackErrorComponent}>
              <ApolloProvider client={apolloClient}>
                <SaveNavigationProvider>
                  <CssBaseline />
                  <AppContextProvider
                    menuItems={pageProps.menuItems}
                    flatMenuItems={pageProps.flatMenuItems}
                    enabledFeatures={pageProps.enabledFeatures}
                    userData={pageProps.userData}
                    appVersion={pageProps.appVersion}
                    tenantCompanyDetails={pageProps.tenantCompanyDetails}
                  >
                    <DomainProvider
                      domainInputData={{
                        domain: "",
                        entity: "",
                        entityValue: "",
                      }}
                    >
                      <NextIntlProvider
                        locale={
                          pageProps.userData?.preferences?.language
                            ? pageProps.userData.preferences.language
                            : "en"
                        }
                        messages={pageProps.messages}
                        hermesUrl={pageProps.hermesUrl}
                        hermesToken={pageProps.hermesToken}
                      >
                        <LoaderBackdrop />
                        <Component {...pageProps} />
                      </NextIntlProvider>
                    </DomainProvider>
                  </AppContextProvider>
                </SaveNavigationProvider>
              </ApolloProvider>
            </ErrorBoundary>
          </GlobalModalProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AppCacheProvider>
  );
};
//@ts-ignore
export const AppComponent = Application;
