import { AppColors, AppPalette, CustomPalette } from "./theme.model";

const appColors: AppColors = {
  primary: "#5A831F",
  onPrimary: "#FFFFFF",
  primaryContainer: "#C1F281",
  onPrimaryContainer: "#112000",
  primaryFixed: "#C1F281",
  onPrimaryFixed: "#112000",
  primaryFixedDim: "#A6D568",
  onPrimaryFixedVariant: "#314F00",
  secondary: "#586249",
  onSecondary: "#FFFFFF",
  secondaryContainer: "#DCE7C7",
  onSecondaryContainer: "#161E0B",
  secondaryFixed: "#DCE7C7",
  onSecondaryFixed: "#161E0B",
  secondaryFixedDim: "#C0CBAC",
  onSecondaryFixedVariant: "#414A33",
  tertiary: "#386662",
  onTertiary: "#FFFFFF",
  tertiaryContainer: "#BCECE7",
  onTertiaryContainer: "#00201E",
  tertiaryFixed: "#BCECE7",
  onTertiaryFixed: "#00201E",
  tertiaryFixedDim: "#A0D0CB",
  onTertiaryFixedVariant: "#1F4E4B",
  error: "#BA1A1A",
  onError: "#FFFFFF",
  errorContainer: "#FFDAD6",
  onErrorContainer: "#410002",
  outline: "#75796C",
  background: "#FEFCF5",
  onBackground: "#1B1C18",
  surface: "#FBFAF2",
  onSurface: "#1B1C18",
  surfaceVariant: "#E1E4D5",
  onSurfaceVariant: "#44483D",
  inverseSurface: "#30312C",
  inverseOnSurface: "#F2F1E9",
  inversePrimary: "#A6D568",
  shadow: "#000000",
  surfaceTint: "#426900",
  outlineVariant: "#C5C8B9",
  scrim: "#000000",
  surfaceContainerHighest: "#E3E3DB",
  surfaceContainerHigh: "#E9E8E1",
  surfaceContainer: "#EFEEE6",
  surfaceContainerLow: "#F5F4EC",
  surfaceContainerLowest: "#FFFFFF",
  surfaceBright: "#FBFAF2",
  surfaceDim: "#DBDAD3",

  backdrop: "#000000",
  relation: { organization: "#0B79D0", person: "#C77700", tag: "#C51162" },
  planning: {
    // planningRowBackground: "#F4F5F4",
  },
  timesheet: {
    orange: "rgba(237, 108, 2, 1)",
    green: "rgba(123, 198, 126, 1)",
  },
  importance: {
    major: { fg: "#d82e18", bg: "#f2bdb4" },
    medium: { fg: "#db7c2e", bg: "#EAAF71" },
    normal: { fg: "#6b6f73", bg: "#edf1f4" },
    low: { fg: "#01a2fa", bg: "#c6e9ff" },
  },
};

const appPalette: AppPalette = {
  primary: {
    "0": "#000000",
    "5": "#091400",
    "10": "#112000",
    "20": "#203600",
    "25": "#284300",
    "30": "#314F00",
    "35": "#395C00",
    "40": "#426900",
    "50": "#5A831F",
    "60": "#729D37",
    "70": "#8CB94F",
    "80": "#A6D568",
    "90": "#C1F281",
    "95": "#D3FF97",
    "98": "#F0FFD6",
    "99": "#F9FFE8",
    "100": "#FFFFFF",
  },
  secondary: {
    "0": "#000000",
    "5": "#0B1303",
    "10": "#161E0B",
    "20": "#2A331E",
    "25": "#353E28",
    "30": "#414A33",
    "35": "#4C563E",
    "40": "#586249",
    "50": "#717B60",
    "60": "#8A9479",
    "70": "#A5AF92",
    "80": "#C0CBAC",
    "90": "#DCE7C7",
    "95": "#EAF5D5",
    "98": "#F3FEDD",
    "99": "#F9FFE8",
    "100": "#FFFFFF",
  },
  tertiary: {
    "0": "#000000",
    "5": "#001413",
    "10": "#00201E",
    "20": "#003734",
    "25": "#024340",
    "30": "#154F4B",
    "35": "#245B57",
    "40": "#316763",
    "50": "#4B807C",
    "60": "#659A95",
    "70": "#7FB5B0",
    "80": "#9AD1CB",
    "90": "#B6EDE7",
    "95": "#C4FBF6",
    "98": "#E3FFFB",
    "99": "#F2FFFD",
    "100": "#FFFFFF",
  },
  error: {
    "0": "#000000",
    "5": "#2D0001",
    "10": "#410002",
    "20": "#690005",
    "25": "#7E0007",
    "30": "#93000A",
    "35": "#A80710",
    "40": "#BA1A1A",
    "50": "#DE3730",
    "60": "#FF5449",
    "70": "#FF897D",
    "80": "#FFB4AB",
    "90": "#FFDAD6",
    "95": "#FFEDEA",
    "98": "#FFF8F7",
    "99": "#FFFBFF",
    "100": "#FFFFFF",
  },
  neutral: {
    "0": "#000000",
    "5": "#10110D",
    "10": "#1B1C18",
    "20": "#30312C",
    "25": "#3B3C37",
    "30": "#464742",
    "35": "#52534D",
    "40": "#5E5F59",
    "50": "#777771",
    "60": "#91918B",
    "70": "#ACACA5",
    "80": "#C7C7C0",
    "90": "#E3E3DB",
    "95": "#F2F1E9",
    "98": "#FBFAF2",
    "99": "#FEFCF5",
    "100": "#FFFFFF",
  },
  neutralVariant: {
    "0": "#000000",
    "5": "#0F1209",
    "10": "#191D14",
    "20": "#2E3228",
    "25": "#393D32",
    "30": "#44483D",
    "35": "#505448",
    "40": "#5C6054",
    "50": "#75796C",
    "60": "#8F9285",
    "70": "#A9AD9F",
    "80": "#C5C8B9",
    "90": "#E1E4D5",
    "95": "#EFF2E3",
    "98": "#F8FBEB",
    "99": "#FBFEEE",
    "100": "#FFFFFF",
  },
};

export const colors: CustomPalette = {
  appColors,
  appPalette,
  palette: {
    // type: "light",
    background: {
      default: appPalette.neutral["99"],
      paper: appColors.surfaceContainerLowest,
    },
    primary: {
      light: appPalette.primary[80],
      main: appPalette.primary[60],
      dark: appPalette.primary[30],
      contrastText: appColors.onPrimary,
    },
    secondary: {
      light: appPalette.secondary[60],
      main: appColors.secondary,
      dark: appPalette.secondary[30],
      contrastText: appColors.onSecondary,
    },
  },
};

// menu color: secondaryContainer
// hover color:
// selected color:
