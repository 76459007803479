"use client";

import React, { useContext, useRef } from "react";
import { Modal, Box, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type ModalContentType = JSX.Element | null;

interface ModalContextType {
  modalContent: ModalContentType;
  childModalContent: ModalContentType;
  handleModal: (
    content?: ModalContentType,
    createAsChild?: boolean,
    onCloseCallback?: () => void
  ) => void;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  isModalActive: boolean;
  isChildModalActive: boolean;
}

const ModalContext = React.createContext<ModalContextType>({
  modalContent: null,
  childModalContent: null,
  handleModal: () => {},
  ref: { current: null },
  isModalActive: false,
  isChildModalActive: false,
});

const GlobalModal = () => {
  const {
    modalContent,
    childModalContent,
    handleModal,
    ref,
    isModalActive,
    isChildModalActive,
  } = useContext(ModalContext);

  return (
    <>
      <Modal
        open={isModalActive}
        aria-labelledby="global-modal"
        aria-describedby="global-modal-description"
      >
        <Stack
          ref={ref}
          sx={{
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            maxWidth: "90%",
            maxHeight: "90%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            border: "1px solid",
            borderColor: (theme) => theme.palette.grey[300],
          }}
        >
          <Stack
            sx={{
              bgcolor: (theme) => theme.palette.grey[100],
              padding: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flex: 1,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => handleModal()}
              sx={{
                color: (theme) => theme.palette.grey[500],
                width: "48px",
                height: "48px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box sx={{ p: 4, overflow: "auto", flexGrow: 1 }}>{modalContent}</Box>
        </Stack>
      </Modal>
      {isChildModalActive && (
        <Modal
          open={isChildModalActive}
          aria-labelledby="child-modal"
          aria-describedby="child-modal-description"
        >
          <Stack
            sx={{
              borderRadius: "10px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              maxWidth: "90%",
              maxHeight: "90%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              border: "1px solid",
              borderColor: (theme) => theme.palette.grey[300],
            }}
          >
            <Stack
              sx={{
                bgcolor: (theme) => theme.palette.grey[100],
                padding: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flex: 1,
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => handleModal(undefined, true)}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  width: "48px",
                  height: "48px",
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack sx={{ p: 4, overflow: "auto", flexGrow: 1 }}>
              {childModalContent}
            </Stack>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export const GlobalModalProvider = (
  props: React.PropsWithChildren<unknown>
) => {
  const [modalContent, setModalContent] =
    React.useState<ModalContentType>(null);
  const [childModalContent, setChildModalContent] =
    React.useState<ModalContentType>(null);
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [isChildModalActive, setIsChildModalActive] = React.useState(false);

  const ref = useRef(null);

  const handleModal = (content?: ModalContentType, createAsChild?: boolean) => {
    if (createAsChild) {
      if (content) {
        setChildModalContent(content);
        setIsChildModalActive(true);
      } else {
        setIsChildModalActive(false);
      }
    } else {
      if (content) {
        setModalContent(content);
        setIsModalActive(true);
      } else {
        setIsModalActive(false);
        setIsChildModalActive(false);
        setChildModalContent(null);
      }
    }
  };

  return (
    <ModalContext.Provider
      value={{
        handleModal,
        modalContent,
        childModalContent,
        ref,
        isModalActive,
        isChildModalActive,
      }}
    >
      <GlobalModal />
      {props.children}
    </ModalContext.Provider>
  );
};

export const useGlobalModal = (): ModalContextType =>
  React.useContext(ModalContext);
