const margin = 10;
const topbarHeight = 48;
const subHeaderbarHeight = 48;
const appDrawerWidth = 220;
const appDrawerItemHeight = 45;
const appDrawerItemWidth = 175;
const pageDrawerWidth = 230;
const pageDrawerWidthSmall = 150;

export const dimensions = {
  topbarHeight: topbarHeight,
  subHeaderbarHeight: subHeaderbarHeight,
  totalTopbarHeight: topbarHeight + margin,
  pageDrawerWidth: pageDrawerWidth,
  pageDrawerWidthSmall: pageDrawerWidthSmall,
  appDrawerWidth: appDrawerWidth,
  appDrawerItemHeight: appDrawerItemHeight,
  appDrawerItemWith: appDrawerItemWidth,
  totalAppDrawerWidth: appDrawerWidth + margin,
  totalAppDrawerWithPageDrawer: appDrawerWidth + pageDrawerWidth + margin * 3,
  totalAppDrawerWithPageDraweSmall:
    appDrawerWidth + pageDrawerWidthSmall + margin * 3,
  margin: margin,
  heightAppBar: topbarHeight,
};

export const getDimensions = () => dimensions;
