import { createHttpLink } from "@apollo/client";
import {
  ErrorResponse,
  onError as onApolloError,
} from "@apollo/client/link/error";
import pino from "pino";

export const httpLink = (
  token: string,
  uri: string,
  headers?: {
    [key: string]: string;
  }
) =>
  createHttpLink({
    uri: uri,
    credentials: "include",
    headers: {
      ...headers,
      authorization: "bearer " + token,
    },
  });

export const errorLink = (onError?: (error: ErrorResponse) => void) =>
  onApolloError((apolloError) => {
    if (apolloError.graphQLErrors) {
      apolloError.graphQLErrors.forEach(({ message, locations, path }) => {
        if (typeof window !== "undefined") {
          const logger = pino({ browser: { asObject: true } });
          logger.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        } else {
          const logger = pino();
          logger.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
      });
    }
    if (apolloError.networkError) {
      if (typeof window !== "undefined") {
        const logger = pino({ browser: { asObject: true } });
        logger.error(`[Network error]: ${apolloError.networkError}`);
      } else {
        const logger = pino();
        logger.error(`[Network error]: ${apolloError.networkError}`);
      }
    }

    onError?.(apolloError);
  });
