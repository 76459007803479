import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const StyledModalDiv = styled(Box)`
  position: "absolute" as "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 400;
  background-color: "background.paper";
  border: "2px solid #000";
  box-shadow: 24;
  p: 5px;
`;

export const TitleDiv = styled.div`
  flex: 1;
`;
export const CloseDiv = styled.div`
  flex: 1;
`;
