import React from "react";
import { Alert, AlertTitle, IconButton, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ErrorAlertProps {
  onClose?: () => void;
  severity?: "error" | "warning" | "info" | "success";
  title?: string;
  message?: string;
}

export const ErrorGeneric: React.FC<ErrorAlertProps> = ({
  onClose,
  severity = "error",
  title = "Error",
  message,
}) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Collapse in={open}>
      <Alert
        severity={severity}
        action={
          onClose && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
      >
        <AlertTitle>{title}</AlertTitle>
        {message !== title ? message : null}
      </Alert>
    </Collapse>
  );
};
