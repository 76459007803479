import gql from "graphql-tag";
export const GET_CONTENT_CATEGORY_HIERARCHY = gql`
  query ContentCategoryStructre {
    CategoryStructure {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
              children {
                id
                name
                children {
                  id
                  name
                  children {
                    id
                    name
                    children {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
