"use client";
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { CustomDialog } from "@patron/ui/modal";
import { useRouter } from "next/navigation";

interface SaveNavigationModal {
  message: string;
  cancelButtonLabel: string;
  navigationButtonLabel: string;
  navigationRoute: string;
}

interface SaveNavigationContextContent {
  saveNavigationModal: SaveNavigationModal | undefined;
  setModalContent: Dispatch<SetStateAction<SaveNavigationModal | undefined>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export const SaveNavigationContext = React.createContext<
  SaveNavigationContextContent | undefined
>(undefined);

export const useSetUnsavedChanges = () => {
  const context = useContext(SaveNavigationContext);
  if (context === undefined) {
    throw new Error(
      "useSetUnsavedChanges must be called within <UnsavedChangesProvider />"
    );
  }
  const { setModalContent } = context;
  const setUnsavedChanges = useCallback(
    (_modalContent: Omit<SaveNavigationModal, "proceedLinkHref">) => {
      setModalContent(_modalContent);
    },
    [setModalContent]
  );
  const clearUnsavedChanges = useCallback(() => {
    setModalContent(undefined);
  }, [setModalContent]);
  return useMemo(
    () => ({ setUnsavedChanges, clearUnsavedChanges }),
    [setUnsavedChanges, clearUnsavedChanges]
  );
};

export const useUnsavedChanges = () => {
  const context = useContext(SaveNavigationContext);
  if (context === undefined) {
    throw new Error(
      "useUnsavedChanges must be called within <UnsavedChangesProvider />"
    );
  }
  const { saveNavigationModal, setModalContent, setShowModal } = context;
  const showUnsavedChangesModal = useCallback(
    (proceedLinkHref: string) => {
      //@ts-ignore
      setModalContent((currentContent) => ({
        ...currentContent,
        proceedLinkHref,
      }));
      setShowModal(true);
    },
    [setModalContent, setShowModal]
  );

  React.useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (saveNavigationModal !== undefined) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [saveNavigationModal]);

  return useMemo(
    () => ({
      currentPageHasUnsavedChanges: saveNavigationModal !== undefined,
      showUnsavedChangesModal,
    }),
    [saveNavigationModal, showUnsavedChangesModal]
  );
};

export const SaveNavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const [modalContent, setModalContent] = useState<
    SaveNavigationModal | undefined
  >(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);

  const context = useMemo(
    (): SaveNavigationContextContent => ({
      saveNavigationModal: modalContent,
      //@ts-ignore
      setModalContent,
      showModal,
      setShowModal,
    }),
    [modalContent, setModalContent, showModal, setShowModal]
  );

  return (
    <SaveNavigationContext.Provider value={context}>
      {children}
      <CustomDialog
        heading={context.saveNavigationModal?.message || ""}
        handleCancel={() => context.setShowModal(false)}
        handleClosePressed={() => {
          context.setShowModal(false);
          if (context.saveNavigationModal?.navigationRoute === "back") {
            router.back();
          } else {
            router.push(context.saveNavigationModal?.navigationRoute || "");
          }

          setModalContent(undefined);
        }}
        leftButtonHeading={context.saveNavigationModal?.cancelButtonLabel || ""}
        rightButtonHeading={
          context.saveNavigationModal?.navigationButtonLabel || ""
        }
        open={context.showModal}
      ></CustomDialog>
    </SaveNavigationContext.Provider>
  );
};

export default SaveNavigationContext;
