import gql from "graphql-tag";
export const GET_ALL_RELATION_ADDRESSES = gql`
  query GetAllRelationAddresses {
    Addresses {
      id
      name
      street
      number
      addressLine2
      postalcode
      city
      suffix
      country
      note
      active
    }
  }
`;
