import gql from "graphql-tag";
export const RELATION_ADDRESSES_BY_TENANT = gql`
  query RelationAddressesByTenant {
    RelationAddressesByTenant {
      id
      organizationID
      addressID
      addressLocationName
      street
      number
      postalcode
      city
      suffix
      organizationName
      addressType
      active
    }
  }
`;
