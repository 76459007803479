"use client";

import React from "react";
import { ApolloLink, from, HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr";
import { errorLink } from "./apollo-links";
import { ErrorResponse } from "@apollo/client/link/error";
// import { useErrorBoundary } from "react-error-boundary";

const createClient = (
  jwt: string | undefined,
  hermesURL?: string,
  clientName?: string,
  hermesToken?: string,
  onError?: (error: ErrorResponse) => void
) => {
  const http = new HttpLink({
    uri: "/query",
    fetchOptions: { cache: "no-store" },
    credentials: "include",
    headers: {
      authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  });
  const httpHermesLink = new HttpLink({
    uri: "/queryhermes",
    fetchOptions: { cache: "no-store" },
    credentials: "include",
    headers: {
      authorization: `Bearer ${hermesToken}`,
      "Content-Type": "application/json",
    },
  });
  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            errorLink(onError),
            new SSRMultipartLink({
              stripDefer: true,
            }),
            new HttpLink({
              uri: clientName
                ? process.env.HERMES_URL + "/queryhermes"
                : process.env.GRAPHQL_URL,
              fetchOptions: { cache: "no-store" },
              credentials: "include",
              headers: {
                authorization: `Bearer ${clientName ? hermesToken : jwt}`,
                "Content-Type": "application/json",
              },
            }),
          ])
        : from([
            errorLink(onError),
            ApolloLink.split(
              (operation) => operation.getContext().clientName === "hermes",
              httpHermesLink, //if above
              http
            ),
          ]),
  });
};

export const ApolloWrapper = ({
  children,
  jwt,
  clientName,
  hermesToken,
  hermesURL,
}: React.PropsWithChildren<{
  jwt: string | undefined;
  clientName?: string;
  hermesToken?: string;
  hermesURL?: string;
}>) => {
  // TODO: Find a way to only throw to error boundary if error is not handled elsewhere
  // const { showBoundary } = useErrorBoundary();
  // const onError = (error: ErrorResponse) => {
  //   showBoundary(error);
  // };

  const makeClient = React.useCallback(
    () => createClient(jwt, hermesURL, clientName, hermesToken),
    [clientName, hermesToken, hermesURL, jwt]
  );

  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
};
