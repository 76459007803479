import gql from "graphql-tag";
export const UNLINK_ADDRESS_FROM_ORGANIZATION = gql`
  mutation UnLinkAddressFromOrganization(
    $input: UnlinkOrganizationAddressParams!
  ) {
    UnLinkOrganizationAddress(input: $input) {
      void
    }
  }
`;
