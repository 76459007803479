import gql from "graphql-tag";
export const ADD_CONTENT_ITEM = gql`
  mutation AddNewContentItem(
    $input: ItemWithCategoryAndRelationDetailsParams!
  ) {
    NewContentItem(input: $input) {
      id
    }
  }
`;
