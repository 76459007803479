import gql from "graphql-tag";
export const GET_HOURS_TO_BE_VALIDATED = gql`
  query GetHoursToBeValidated {
    HoursToBeValidated {
      timesheetID
      dateTime
      employee {
        id
        firstName
        lastName
      }
      amountValidated
      amount
      note
      activities
      project {
        id
        name
        organization {
          id
          organizationPath
          name
        }
        projectType {
          id
          name
        }
      }
    }
  }
`;
