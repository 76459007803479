import React from "react";
import { Html, Head, Main, NextScript, DocumentProps } from "next/document";
import {
  DocumentHeadTags,
  documentGetInitialProps,
} from "@mui/material-nextjs/v14-pagesRouter";
import type { DocumentHeadTagsProps } from "@mui/material-nextjs/v14-pagesRouter";

export const MyDocument = (props: DocumentProps & DocumentHeadTagsProps) => (
  <Html lang="en">
    <Head>
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta name="emotion-insertion-point" content="" />
      <DocumentHeadTags {...props} />
    </Head>
    <body>
      <Main />
      <NextScript />
    </body>
  </Html>
);

MyDocument.getInitialProps = documentGetInitialProps;
