"use client";

import React from "react";
import {
  IamUser,
  TenantDetailsRow,
  MenuItem,
  TenantFlatMenus,
} from "@patron/data-access-server";

interface AppContextproviderProps {
  children:
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | boolean
    | null
    | undefined;
  userData?: IamUser;
  enabledFeatures: string[];
  menuItems?: MenuItem[];
  flatMenuItems?: TenantFlatMenus[];
  tenantCompanyDetails?: TenantDetailsRow;
  appVersion?: string;
  queryProcessing?: boolean;
}

interface AppContextState {
  userData?: IamUser;
  enabledFeatures?: string[];
  menuItems?: MenuItem[];
  tenantFlatMenuItems?: TenantFlatMenus[];
  tenantCompanyDetails?: TenantDetailsRow;
  appVersion?: string;
  queryProcessing?: boolean;
}

export interface ContextState {
  localAppState: AppContextState;
  setLocalAppState: React.Dispatch<React.SetStateAction<AppContextState>>;
}
export const AppContext = React.createContext<ContextState>({
  localAppState: {},
  setLocalAppState: () => null,
});

export const AppContextProvider = (props: AppContextproviderProps) => {
  const {
    userData,
    tenantCompanyDetails,
    enabledFeatures,
    menuItems,
    flatMenuItems,
    appVersion,
  } = props;
  const [localAppState, setLocalAppState] = React.useState<AppContextState>({
    userData: userData,
    menuItems: menuItems,
    tenantFlatMenuItems: flatMenuItems,
    enabledFeatures: enabledFeatures,
    tenantCompanyDetails: tenantCompanyDetails,
    appVersion: appVersion,
    queryProcessing: false,
  });

  const context: ContextState = {
    localAppState,
    setLocalAppState,
  };
  return (
    <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
  );
};
// export AppContextProvider;
