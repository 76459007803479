"use client";

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AppContext } from "@patron/utils/context";

export const LoaderBackdrop = () => {
  const { queryProcessing } = React.useContext(AppContext).localAppState;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if (queryProcessing) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [queryProcessing]);

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (muiTheme) => muiTheme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LoaderBackdrop;
